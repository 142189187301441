/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import styled from 'styled-components';
import downloadFile from '../downloads/2020-07-10 Floating energy brochure.pdf';

const LinkButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const LinkButton = styled.a`
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  background-color: #185856;
  color: #fff;
  padding: 20px 15px;
  margin: 1rem auto;
  font-size: 1.2rem;
`;

const DownloadButton = () => (
  <LinkButtonContainer>
    <LinkButton
      href={downloadFile}
      target="_blank"
      rel="noreferrer"
      title="Download de brochure in PDF"
    >
      Download Brochure
    </LinkButton>
  </LinkButtonContainer>
);

export default DownloadButton;
