/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const settings = {
  dots: true,
  arrows: false,
  adaptiveHeight: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 3000,
  fade: true,
  dotsClass: 'slick-dots slick-dots-custom',
};

// markup

const SliderContainer = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "slider" }
        }
        sort: { fields: base, order: ASC }
      ) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(
                quality: 90
                layout: CONSTRAINED
                width: 1200
                height: 600
                transformOptions: { fit: COVER, cropFocus: CENTER }
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);
  return (
    <Slider {...settings}>
      {data.allFile.edges.map((image) => (
        <GatsbyImage
          image={getImage(image.node.childImageSharp)}
          key={image.node.base}
          alt={image.node.base}
          imgClassName="slider-imagex"
          objectFit="contain"
        />
      ))}
    </Slider>
  );
};

export default SliderContainer;
