import * as React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SliderContainer from '../components/slider';
import DownloadButton from '../components/download-button';
import Menu from '../components/menu';

const Container = styled.main`
  overflow: hidden;
  padding-top: 8px;
  width: 90vmin;
  margin: 0 auto;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  background-color: #185856;
  color: #fff;
  padding: 20px 15px;
  margin: 0 auto;
  font-size: 2.5rem;
  font-weight: 900;
  @media (min-width: 600px) {
    font-size: 3rem;
  }
`;

const SubTitle = styled.h2`
  display: inline-block;
  font-size: 1rem;
  color: #185856;
  margin: 20px auto 20px;
  padding: 0;
  text-align: center;
`;

// markup
const IndexPage = () => (
  <Layout>
    <Menu />
    <Container id="page-container">
      <Header>
        <Title>Floating Energie+</Title>
        <SubTitle>
          Zonne-energie voor duurzame sportinnovatie en multifunctioneel
          ruimtegebruik
        </SubTitle>
      </Header>

      <SliderContainer />
      <DownloadButton />
    </Container>
  </Layout>
);

export default IndexPage;
