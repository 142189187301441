import React from 'react';
import { createGlobalStyle } from 'styled-components';
import './layout.css';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  background: url(../images/background.jpg) no-repeat right bottom fixed;
  background-size: contain;
  }
`;
export default function Layout({ children }) {
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  );
}
