import React from 'react';
import styled from 'styled-components';
import { slide as SlideMenu } from 'react-burger-menu';

const Address = styled.p`
  font-size: 1.2rem;
  line-height: 1.2;
  color: #fff;
  outline: none;
`;

const Menu = () => (
  <SlideMenu
    right
    itemListElement="div"
    width="100%"
    pageWrapId="page-container"
  >
    <Address>
      VLUGP
      <br />
      Pedro de Medinalaan 128
      <br />
      1086 XR Amsterdam
      <br />T <a href="tel:+31206923007">020-6923007</a>
      <br />E <a href="mailto:buro@vlugp.nl">buro@vlugp.nl</a>
      <br />S{' '}
      <a href="https://www.vlugp.nl" target="_blank" rel="noreferrer">
        www.vlugp.nl
      </a>
      <br />
      <br />
      Dhr. M. (Menno) Vergunst
      <br />
      Dhr. P. (Paul) van der Kolk <br />
      Dhr. H. (Henk) Schuitemaker
      <br />
    </Address>
  </SlideMenu>
);

export default Menu;
